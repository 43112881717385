/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';


const $ = require('jquery');
require('imagesloaded');
// require('linearicons');
require('bootstrap');
require('superfish');
require('magnific-popup');
require('jquery-nice-select');
require('owl.carousel2');
require('@fortawesome/fontawesome-free/js/all.js');
require('./js/jquery.nav.js');
require('./js/main.js');
