

$(document).ready(function() {
    $('#nav-menu-container').onePageNav();
});

window.setTimeout(function () {
    $(".alert-dismissible").fadeTo(500, 0).slideUp(500, function () {
        $(this).remove();
    });
}, 4000);

function justifiedGrid(i) {
    function e(i) {
        return i.width() >= i.height() ? "l" : "p"
    }

    function n(i) {
        i.each(function (i) {
            $(this).css("padding", o + "px"), $(this).attr("data-index", i), $(this).addClass("hgrid-item loaded"), $(this).removeClass("resized")
        }), i.each(function () {
            $(this).css("width", $(this).find("img").width()), $(this).css("height", $(this).find("img").height()), $(this).addClass("resized")
        }), orientations = new Array, i.each(function () {
            orientations.push(e($(this)))
        })
    }

    function t() {
        $(window).width() >= 960 && (max_line_score = 8), $(window).width() >= 660 && $(window).width() <= 960 && (max_line_score = 6), $(window).width() <= 660 && (max_line_score = 4);
        var i = 0, e = orientations.length;
        lines = new Array;
        var n = new Array;
        line_score = new Array, orientations.forEach(function (t, s) {
            "l" == t ? i + 2 <= max_line_score ? s != e - 1 ? (i += 2, n.push($(".hgrid-item[data-index=" + s + "]"))) : (i += 2, line_score.push(i), n.push($(".hgrid-item[data-index=" + s + "]")), lines.push(n)) : s != e - 1 ? (line_score.push(i), i = 2, lines.push(n), n = new Array, n.push($(".hgrid-item[data-index=" + s + "]"))) : (line_score.push(i), i = 2, line_score.push(i), lines.push(n), n = new Array, n.push($(".hgrid-item[data-index=" + s + "]")), lines.push(n)) : i + 1 <= max_line_score ? s != e - 1 ? (i += 1, n.push($(".hgrid-item[data-index=" + s + "]"))) : (i += 1, line_score.push(i), n.push($(".hgrid-item[data-index=" + s + "]")), lines.push(n)) : s != e - 1 ? (line_score.push(i), i = 1, lines.push(n), n = new Array, n.push($(".hgrid-item[data-index=" + s + "]"))) : (line_score.push(i), i = 1, line_score.push(i), lines.push(n), n = new Array, n.push($(".hgrid-item[data-index=" + s + "]")), lines.push(n))
        })
    }

    function s() {
        lines.forEach(function (i, e) {
            if (line_score[e] >= max_line_score - 1) {
                i.length;
                $theContainer = h;
                var n = $theContainer.width() - 2, t = 0, s = [], r = [], a = [], o = 0;
                i.forEach(function (i, e) {
                    var n = i.width(), t = i.height();
                    s.push(n), r.push(t), a.push(n / t), o += 1
                });
                var d = [], u = 0;
                for (f = 0; o - 1 >= f; f++) {
                    for (j = 0; j <= o - 1; j++) u += a[j] / a[f];
                    var c = (n - (o - 1) * t) / u;
                    d.push(c), u = 0
                }
                var l = d[0] / a[0], f = 0;
                i.forEach(function (i, e) {
                    i.css({height: l, width: d[f]}), i.animate({opacity: 1}, 500), f += 1
                })
            } else {
                i.length;
                if ($theContainer = h, line_score[e] <= max_line_score / 2) var n = $theContainer.width() / 2 - 2; else var n = $theContainer.width() - $theContainer.width() / 3 - 2;
                var t = 0, s = [], r = [], a = [], o = 0;
                i.forEach(function (i, e) {
                    var n = i.width(), t = i.height();
                    s.push(n), r.push(t), a.push(n / t), o += 1
                });
                var d = [], u = 0;
                for (f = 0; o - 1 >= f; f++) {
                    for (j = 0; j <= o - 1; j++) u += a[j] / a[f];
                    var c = (n - (o - 1) * t) / u;
                    d.push(c), u = 0
                }
                var l = d[0] / a[0], f = 0;
                i.forEach(function (i, e) {
                    i.css({height: l, width: d[f]}), i.animate({opacity: 1}, 500), f += 1
                })
            }
        })
    }

    var h = $(i.gridContainer), r = $(i.gridItems), a = i.enableImagesLoaded, o = i.gutter;
    this.reInitGrid = function () {
        r.each(function () {
            $(this).attr("style", "")
        }), n(r), t(), s()
    }, this.initGrid = function () {
        var i = this;
        var e = $("#div").find("img, iframe"), o = e.length, d = 0;
        e.on("load", function () {
            d++, d == o && (n(r), t(), s())
        }), i.reInitGrid()
        $(window).on("resize", function () {
            i.reInitGrid()
        })
    }
}

$(document).ready(function () {
    "use strict";

    var window_width = $(window).width(),
        window_height = window.innerHeight,
        header_height = $(".default-header").height(),
        header_height_static = $(".site-header.static").outerHeight(),
        fitscreen = window_height - header_height;


    $(".fullscreen").css("height", window_height)
    $(".fitscreen").css("height", fitscreen);

    if (document.getElementById("default-select")) {
        $('select').niceSelect();
    }
    ;

    $('.img-pop-up').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    $('.single-gallery').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    $('.recent-project').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });


    $('.play-btn').magnificPopup({
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });


    // Initiate superfish on nav menu
    $('.nav-menu').superfish({
        animation: {
            opacity: 'show'
        },
        speed: 400
    });

    // // Mobile Navigation
    // if ($('#nav-menu-container').length) {
    //     var $mobile_nav = $('#nav-menu-container').clone().prop({
    //         id: 'mobile-nav'
    //     });
    //     $mobile_nav.find('> ul').attr({
    //         'class': '',
    //         'id': ''
    //     });
    //     $('body').append($mobile_nav);
    //     $('body').prepend('<button type="button" id="mobile-nav-toggle"><i class="lnr lnr-menu"></i></button>');
    //     $('body').append('<div id="mobile-body-overly"></div>');
    //     $('#mobile-nav').find('.menu-has-children').prepend('<i class="lnr lnr-chevron-down"></i>');
    //
    //     $(document).on('click', '.menu-has-children i', function (e) {
    //         $(this).next().toggleClass('menu-item-active');
    //         $(this).nextAll('ul').eq(0).slideToggle();
    //         $(this).toggleClass("lnr-chevron-up lnr-chevron-down");
    //     });
    //
    //     $(document).on('click', '#mobile-nav-toggle', function (e) {
    //         $('body').toggleClass('mobile-nav-active');
    //         $('#mobile-nav-toggle i').toggleClass('lnr-cross lnr-menu');
    //         $('#mobile-body-overly').toggle();
    //     });
    //
    //     $(document).click(function (e) {
    //         var container = $("#mobile-nav, #mobile-nav-toggle");
    //         if (!container.is(e.target) && container.has(e.target).length === 0) {
    //             if ($('body').hasClass('mobile-nav-active')) {
    //                 $('body').removeClass('mobile-nav-active');
    //                 $('#mobile-nav-toggle i').toggleClass('lnr-cross lnr-menu');
    //                 $('#mobile-body-overly').fadeOut();
    //             }
    //         }
    //     });
    // } else if ($("#mobile-nav, #mobile-nav-toggle").length) {
    //     $("#mobile-nav, #mobile-nav-toggle").hide();
    // }


    $(document).ready(function () {

        $('html, body').hide();

        if (window.location.hash) {

            setTimeout(function () {

                $('html, body').scrollTop(0).show();

                $('html, body').animate({

                    scrollTop: $(window.location.hash).offset().top - 100

                }, 1000)

            }, 0);

        } else {

            $('html, body').show();

        }

    });


    // Header scroll class
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('#header').addClass('header-scrolled');
        } else {
            $('#header').removeClass('header-scrolled');
        }
    })


    $('.active-about-carusel').owlCarousel({
        items: 1,
        loop: true,
        margin: 30,
        dots: true
    });

    $('.active-exibition-carusel').owlCarousel({
        items: 3,
        margin: 30,
        autoplay: true,
        loop: true,
        dots: true,
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 1,
            },
            768: {
                items: 2,
            },
            900: {
                items: 3,
            }

        }
    });


    //  Gallery


    var parameters = {
        gridContainer: '#grid-container',
        gridItems: '.grid-item',
        gutter: 15,
        enableImagesLoaded: false
    };
    var grid = new justifiedGrid(parameters);
    // $('body').imagesLoaded(function () {
    //     grid.initGrid();
    // });


    //  Start Google map

    // When the window has finished loading create our google map below

    if (document.getElementById("map")) {

        google.maps.event.addDomListener(window, 'load', init);

        function init() {
            // Basic options for a simple Google Map
            // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
            var mapOptions = {
                // How zoomed in you want the map to start at (always required)
                zoom: 11,

                // The latitude and longitude to center the map (always required)
                center: new google.maps.LatLng(40.6700, -73.9400), // New York

                // How you would like to style the map.
                // This is where you would paste any style found on Snazzy Maps.
                styles: [{
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [{"color": "#e9e9e9"}, {"lightness": 17}]
                }, {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [{"color": "#f5f5f5"}, {"lightness": 20}]
                }, {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [{"color": "#ffffff"}, {"lightness": 17}]
                }, {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [{"color": "#ffffff"}, {"lightness": 29}, {"weight": 0.2}]
                }, {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [{"color": "#ffffff"}, {"lightness": 18}]
                }, {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [{"color": "#ffffff"}, {"lightness": 16}]
                }, {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [{"color": "#f5f5f5"}, {"lightness": 21}]
                }, {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [{"color": "#dedede"}, {"lightness": 21}]
                }, {
                    "elementType": "labels.text.stroke",
                    "stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"lightness": 16}]
                }, {
                    "elementType": "labels.text.fill",
                    "stylers": [{"saturation": 36}, {"color": "#333333"}, {"lightness": 40}]
                }, {"elementType": "labels.icon", "stylers": [{"visibility": "off"}]}, {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [{"color": "#f2f2f2"}, {"lightness": 19}]
                }, {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [{"color": "#fefefe"}, {"lightness": 20}]
                }, {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [{"color": "#fefefe"}, {"lightness": 17}, {"weight": 1.2}]
                }]
            };

            // Get the HTML DOM element that will contain your map
            // We are using a div with id="map" seen below in the <body>
            var mapElement = document.getElementById('map');

            // Create the Google Map using our element and options defined above
            var map = new google.maps.Map(mapElement, mapOptions);

            // Let's also add a marker while we're at it
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(40.6700, -73.9400),
                map: map,
                title: 'Snazzy!'
            });
        }
    }


    // $(document).ready(function () {
    //     $('#mc_embed_signup').find('form').ajaxChimp();
    // });


});

$(document).ready(function () {
    "use strict";

    var window_width = $(window).width(),
        window_height = window.innerHeight,
        header_height = $(".default-header").height(),
        header_height_static = $(".site-header.static").outerHeight(),
        fitscreen = window_height - header_height;


    $(".fullscreen").css("height", window_height)
    $(".fitscreen").css("height", fitscreen);

    if (document.getElementById("default-select")) {
        $('select').niceSelect();
    }
    ;

    $('.img-pop-up').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    $('.single-gallery').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    $('.recent-project').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });


    $('.play-btn').magnificPopup({
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });


    // Initiate superfish on nav menu
    $('.nav-menu').superfish({
        animation: {
            opacity: 'show'
        },
        speed: 400
    });

    // Mobile Navigation
    if ($('#nav-menu-container').length) {
        var $mobile_nav = $('#nav-menu-container').clone().prop({
            id: 'mobile-nav'
        });
        $mobile_nav.find('> ul').attr({
            'class': '',
            'id': ''
        });
        $('body').append($mobile_nav);
        $('body').prepend('<button type="button" id="mobile-nav-toggle"><i class="lnr lnr-menu"></i></button>');
        $('body').append('<div id="mobile-body-overly"></div>');
        $('#mobile-nav').find('.menu-has-children').prepend('<i class="lnr lnr-chevron-down"></i>');

        $(document).on('click', '.menu-has-children i', function (e) {
            $(this).next().toggleClass('menu-item-active');
            $(this).nextAll('ul').eq(0).slideToggle();
            $(this).toggleClass("lnr-chevron-up lnr-chevron-down");
        });

        $(document).on('click', '#mobile-nav-toggle', function (e) {
            $('body').toggleClass('mobile-nav-active');
            $('#mobile-nav-toggle i').toggleClass('lnr-cross lnr-menu');
            $('#mobile-body-overly').toggle();
        });

        $(document).click(function (e) {
            var container = $("#mobile-nav, #mobile-nav-toggle");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                if ($('body').hasClass('mobile-nav-active')) {
                    $('body').removeClass('mobile-nav-active');
                    $('#mobile-nav-toggle i').toggleClass('lnr-cross lnr-menu');
                    $('#mobile-body-overly').fadeOut();
                }
            }
        });

        $(document).on('click', 'a', function (e) {
            if ($('body').hasClass('mobile-nav-active')) {
                $('body').removeClass('mobile-nav-active');
                $('#mobile-nav-toggle i').toggleClass('lnr-cross lnr-menu');
                $('#mobile-body-overly').fadeOut();
            }


        })
    } else if ($("#mobile-nav, #mobile-nav-toggle").length) {
        $("#mobile-nav, #mobile-nav-toggle").hide();
    }


    $(document).ready(function () {

        $('html, body').hide();

        if (window.location.hash) {

            setTimeout(function () {

                $('html, body').scrollTop(0).show();

                $('html, body').animate({

                    scrollTop: $(window.location.hash).offset().top - 100

                }, 1000)

            }, 0);

        } else {

            $('html, body').show();

        }

    });


    // Header scroll class
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('#header').addClass('header-scrolled');
        } else {
            $('#header').removeClass('header-scrolled');
        }
    })


    $('.active-about-carusel').owlCarousel({
        items: 1,
        loop: true,
        margin: 30,
        dots: true
    });

    $('.active-exibition-carusel').owlCarousel({
        items: 3,
        margin: 30,
        autoplay: true,
        loop: true,
        dots: true,
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 1,
            },
            768: {
                items: 2,
            },
            900: {
                items: 3,
            }

        }
    });


    //  Gallery


    var parameters = {
        gridContainer: '#grid-container',
        gridItems: '.grid-item',
        gutter: 15,
        enableImagesLoaded: true
    };
    var grid = new justifiedGrid(parameters);
    // $('body').imagesLoaded(function () {
    //     grid.initGrid();
    // });


    //  Start Google map

    // When the window has finished loading create our google map below

    if (document.getElementById("map")) {

        google.maps.event.addDomListener(window, 'load', init);

        function init() {
            // Basic options for a simple Google Map
            // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
            var mapOptions = {
                // How zoomed in you want the map to start at (always required)
                zoom: 11,

                // The latitude and longitude to center the map (always required)
                center: new google.maps.LatLng(40.6700, -73.9400), // New York

                // How you would like to style the map.
                // This is where you would paste any style found on Snazzy Maps.
                styles: [{
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [{"color": "#e9e9e9"}, {"lightness": 17}]
                }, {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [{"color": "#f5f5f5"}, {"lightness": 20}]
                }, {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [{"color": "#ffffff"}, {"lightness": 17}]
                }, {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [{"color": "#ffffff"}, {"lightness": 29}, {"weight": 0.2}]
                }, {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [{"color": "#ffffff"}, {"lightness": 18}]
                }, {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [{"color": "#ffffff"}, {"lightness": 16}]
                }, {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [{"color": "#f5f5f5"}, {"lightness": 21}]
                }, {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [{"color": "#dedede"}, {"lightness": 21}]
                }, {
                    "elementType": "labels.text.stroke",
                    "stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"lightness": 16}]
                }, {
                    "elementType": "labels.text.fill",
                    "stylers": [{"saturation": 36}, {"color": "#333333"}, {"lightness": 40}]
                }, {"elementType": "labels.icon", "stylers": [{"visibility": "off"}]}, {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [{"color": "#f2f2f2"}, {"lightness": 19}]
                }, {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [{"color": "#fefefe"}, {"lightness": 20}]
                }, {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [{"color": "#fefefe"}, {"lightness": 17}, {"weight": 1.2}]
                }]
            };

            // Get the HTML DOM element that will contain your map
            // We are using a div with id="map" seen below in the <body>
            var mapElement = document.getElementById('map');

            // Create the Google Map using our element and options defined above
            var map = new google.maps.Map(mapElement, mapOptions);

            // Let's also add a marker while we're at it
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(40.6700, -73.9400),
                map: map,
                title: 'Snazzy!'
            });
        }
    }


    // $(document).ready(function () {
    //     $('#mc_embed_signup').find('form').ajaxChimp();
    // });


});
